// JsFromRoutes CacheKey 7f65562d622373e70485c5f12691b281
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  create: /* #__PURE__ */ definePathHelper('post', '/sessions'),
  new: /* #__PURE__ */ definePathHelper('get', '/sessions/new'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/sessions'),
}
