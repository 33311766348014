import { Controller } from "@hotwired/stimulus";
import start, { type AppUser } from "../services/auth.js";
import { sessions, home, checkouts } from "../api/all.js";
import type ToastController from "./toast_controller.ts";

export default class extends Controller {
  auth: ReturnType<typeof start> | null = null;
  confirmFn: null | ((code: string) => Promise<AppUser>) = null;
  afterAuthPath: string | null = null;
  afterAuthShouldRepost: boolean = false;
  toast: ToastController | null = null;

  static override targets = [
    "phoneInput",
    "phoneError",
    "phoneWrapper",
    "confirmationWrapper",
    "confirmationInput",
    "confirmationError",
    "submitButton",
    "loadingIndicator",
    "submitContent",
  ];

  declare phoneWrapperTarget: HTMLDivElement;
  declare phoneInputTarget: HTMLInputElement;
  declare phoneErrorTarget: HTMLParagraphElement;
  declare confirmationWrapperTarget: HTMLDivElement;
  declare confirmationInputTarget: HTMLInputElement;
  declare confirmationErrorTarget: HTMLParagraphElement;
  declare submitButtonTarget: HTMLButtonElement;
  declare loadingIndicatorTarget: HTMLSpanElement;
  declare submitContentTarget: HTMLSpanElement;

  override initialize() {
    const auth = start({
      signInButtonId: "sign-in-button",
    });
    this.auth = auth;
    if (location.search.includes("after_auth_path")) {
      const params = new URLSearchParams(location.search);
      const rawPath = params.get("after_auth_path");
      if (!rawPath) return;
      const decoded = decodeURI(rawPath);
      this.afterAuthPath = decoded;
      const repost = params.get("repost");
      if (repost) this.afterAuthShouldRepost = repost === "true";
    }
  }

  override connect() {
    this.setInitialState();
    this.addKeyboardListeners();
    this.phoneInputTarget.focus();
  }

  setInitialState() {
    const toastEl = document.querySelector("[data-controller='toast']");
    if (!toastEl) {
      console.error("failed to find toast controller element");
    } else {
      this.toast = this.application.getControllerForElementAndIdentifier(
        toastEl,
        "toast",
      ) as ToastController;
    }
    this.phoneWrapperTarget.classList.remove("hidden");
    this.confirmationWrapperTarget.classList.add("hidden");
    // this.submitButtonTarget.textContent = "Sign In";
    // this.submitButtonTarget.dataset.action = "click->auth#submitPhone";
  }

  addKeyboardListeners() {
    this.phoneInputTarget.addEventListener(
      "keyup",
      this.handlePhoneInputKeyup.bind(this),
    );
    this.confirmationInputTarget.addEventListener(
      "keyup",
      this.handleConfirmationInputKeyup.bind(this),
    );
  }

  handlePhoneInputKeyup(event: KeyboardEvent) {
    if (event.key === "Enter" && this.validatePhone()) {
      this.submitPhone();
    }
  }

  handleConfirmationInputKeyup(event: KeyboardEvent) {
    if (event.key === "Enter" && this.validateConfirmation()) {
      this.submitConfirmation(event);
    }
  }

  async signOut() {
    await this.auth?.signOutFn();
  }

  validatePhone(): boolean {
    const phoneNumber = this.phoneInputTarget.value.replace(/\D/g, "");
    if (phoneNumber.length === 10) {
      this.submitButtonTarget.removeAttribute("disabled");
      this.phoneInputTarget.classList.add("input-success");
      this.phoneInputTarget.classList.remove("input-error");
      this.phoneErrorTarget.classList.remove("visible");
      this.phoneErrorTarget.classList.add("invisible");
      return true;
    } else {
      this.submitButtonTarget.setAttribute("disabled", "true");
      this.phoneInputTarget.classList.add("input-error");
      this.phoneInputTarget.classList.remove("input-success");
      this.phoneErrorTarget.classList.remove("invisible");
      this.phoneErrorTarget.classList.add("visible");
      return false;
    }
  }

  async submitPhone() {
    const phoneNumber = this.phoneInputTarget.value.replace(/\D/g, "");
    if (phoneNumber.length === 10) {
      // TODO: validate/parse to E164 format
      const e164PhoneNumber = `+1${phoneNumber}`;
      try {
        this.phoneInputTarget.setAttribute("disabled", "true");
        this.submitButtonTarget.setAttribute("disabled", "true");
        this.loadingIndicatorTarget.classList.remove("hidden");
        this.confirmFn = (await this.auth?.signInFn(e164PhoneNumber)) ?? null;
        this.loadingIndicatorTarget.classList.add("hidden");
        this.submitButtonTarget.removeAttribute("disabled");
        this.phoneInputTarget.removeAttribute("disabled");
        if (this.confirmFn) {
          this.phoneWrapperTarget.classList.add("hidden");
          this.confirmationWrapperTarget.classList.remove("hidden");
          this.submitContentTarget.textContent = "Verify";
          this.submitButtonTarget.setAttribute("disabled", "true");
          this.submitButtonTarget.dataset.action =
            "click->auth#submitConfirmation";
          this.confirmationInputTarget.focus();
        } else {
          throw new Error("Failed to initiate sign in");
        }
      } catch (error) {
        console.error("Error during phone submission:", error);
        this.toast?.create(
          "Something went wrong with your log in attempt. Please try again.",
          "error",
        );
        this.phoneInputTarget.removeAttribute("disabled");
        this.phoneErrorTarget.textContent =
          "An error occurred. Please try again.";
        this.submitButtonTarget.removeAttribute("disabled");
        this.loadingIndicatorTarget.classList.add("hidden");
      }
    }
  }

  validateConfirmation(): boolean {
    const confirmationCode = this.confirmationInputTarget.value;
    if (confirmationCode.length === 6 && /^\d+$/.test(confirmationCode)) {
      this.submitButtonTarget.removeAttribute("disabled");
      this.confirmationInputTarget.classList.add("input-success");
      this.confirmationInputTarget.classList.remove("input-error");
      this.confirmationErrorTarget.classList.remove("visible");
      this.confirmationErrorTarget.classList.add("invisible");
      return true;
    } else {
      this.submitButtonTarget.setAttribute("disabled", "true");
      this.confirmationErrorTarget.textContent =
        "Please enter a valid 6-digit code";
      this.confirmationInputTarget.classList.add("input-error");
      this.confirmationInputTarget.classList.remove("input-success");
      this.confirmationErrorTarget.classList.remove("invisible");
      this.confirmationErrorTarget.classList.add("visible");
      return false;
    }
  }

  tryReadAfterAuthPath() {
    let afterAuthPath = this.afterAuthPath;
    let shouldRepost = this.afterAuthShouldRepost;

    if (!!afterAuthPath) {
      return { afterAuthPath, shouldRepost };
    }

    if (location.search.includes("after_auth_path")) {
      const params = new URLSearchParams(location.search);
      const rawPath = params.get("after_auth_path");
      if (!!rawPath) {
        afterAuthPath = decodeURI(rawPath);
        const repost = params.get("repost");
        if (repost) shouldRepost = repost === "true";
        return { afterAuthPath, shouldRepost };
      }
    }

    if (location.pathname.startsWith(sessions.new.path())) {
      // NOTE: avoid reloading the login page
      afterAuthPath = home.index.path();
    }
    shouldRepost = false;

    return { afterAuthPath, shouldRepost };
  }

  handleAfterAuth() {
    const { afterAuthPath, shouldRepost } = this.tryReadAfterAuthPath();

    if (!afterAuthPath) {
      return location.reload();
    }

    if (!shouldRepost) {
      this.toast?.create(
        "You’re logged in! Picking up where you left off...",
        "success",
      );
      // NOTE: small delay to allow time to read toast
      setTimeout(() => (location.href = afterAuthPath), 1000);
      return;
    }

    const form = document.createElement("form");
    form.method = "POST";
    form.action = afterAuthPath;
    document.body.appendChild(form);

    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content");
    if (csrfToken) {
      const csrfInput = document.createElement("input");
      csrfInput.type = "hidden";
      csrfInput.name = "authenticity_token";
      csrfInput.value = csrfToken;
      form.appendChild(csrfInput);
    } else {
      console.error(
        "unable to find csrf token in meta tags, form repost will fail",
      );
      // NOTE: post will not work without crsf in prod but will in test....
      if (import.meta.env.RAILS_ENV === "production") {
        this.toast?.create("You’re logged in!", "success");
        this.toast?.create(
          "Unable to continue your previous action. Please try your request again.",
          "warning",
        );
        // NOTE: slightly longer delay to allow time to read toast
        setTimeout(() => (location.href = home.index.path()), 2000);
        return;
      }
    }

    if (afterAuthPath?.startsWith(checkouts.create.path())) {
      this.toast?.create(
        "You’re logged in! Continuing your reservation...",
        "success",
      );
    } else {
      this.toast?.create(
        "You’re logged in! Picking up where you left off...",
        "success",
      );
    }
    // NOTE: small delay to allow time to read toast
    setTimeout(() => form.submit(), 1000);
  }

  async submitConfirmation(event: MouseEvent | KeyboardEvent) {
    event.preventDefault();
    const confirmationCode = this.confirmationInputTarget.value;
    if (confirmationCode.length === 6 && /^\d+$/.test(confirmationCode)) {
      let user = null;
      try {
        this.confirmationInputTarget.setAttribute("disabled", "true");
        this.submitButtonTarget.setAttribute("disabled", "true");
        this.loadingIndicatorTarget.classList.remove("hidden");
        // TODO: handle case where somehow confirmFn is null
        user = await this.confirmFn?.(confirmationCode);
      } catch (error) {
        this.toast?.create(
          "Something went wrong with your confirmation attempt. Please check your code and try again.",
          "error",
        );

        this.phoneInputTarget.removeAttribute("disabled");
        this.submitButtonTarget.removeAttribute("disabled");
        this.loadingIndicatorTarget.classList.add("hidden");
        this.confirmationInputTarget.removeAttribute("disabled");
        this.confirmationInputTarget.classList.add("input-error");
        this.confirmationInputTarget.classList.remove("input-success");
        this.confirmationErrorTarget.classList.remove("invisible");
        this.confirmationErrorTarget.classList.add("visible");
        this.confirmationErrorTarget.textContent =
          "Invalid code. Please try again.";
      }

      if (user) {
        this.loadingIndicatorTarget.classList.add("hidden");
        this.submitButtonTarget.classList.add("btn-success");
        this.submitButtonTarget.classList.remove("btn-primary");
        this.submitContentTarget.textContent = "✔";
        this.submitButtonTarget.removeAttribute("disabled");
        this.handleAfterAuth();
      } else {
        this.confirmationInputTarget.removeAttribute("disabled");
      }
    }
  }
}
