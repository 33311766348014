import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

// Start the Stimulus application.
const application = Application.start();

// Configure Stimulus development experience
import.meta.env.RAILS_ENV === "development" && (application.debug = true);
// @ts-expect-error // TODO: fix this
window.Stimulus = application;
// end condition on dev env

// Controller files must be named *_controller.js. or [...].ts
const controllers = import.meta.glob("./**/*_controller.(j|t)s", {
  eager: true,
});
registerControllers(application, controllers);
