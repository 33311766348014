import { Controller } from "@hotwired/stimulus";

export type ToastType = "info" | "error" | "success" | "warning";

export default class ToastController extends Controller {
  static override targets = ["toast"];

  declare toastTargets: HTMLElement[];

  override connect() {
    this.toastTargets.forEach((toast) => {
      const dismissButton = toast.querySelector(
        '[data-action="click->toast#dismiss"]',
      );
      dismissButton?.addEventListener("click", this.dismiss.bind(this));
    });
  }

  dismiss(event: Event) {
    const button = event.target as HTMLElement;
    const toast = button.closest(
      '[data-toast-target="toast"]',
    ) as HTMLDivElement;
    toast.remove();
  }

  create(message: string, type: ToastType = "info") {
    const toastContainer = document.querySelector("#toast-container");
    if (!toastContainer) {
      console.error(
        `No toast container found; couldn’t create toast for message '${message}'.`,
      );
      return;
    }

    const newToast = document.createElement("div");
    newToast.classList.add(
      "w-fit",
      "flex",
      "glass",
      "alert",
      `alert-${type}`,
      "shadow-lg",
    );
    newToast.setAttribute("data-toast-target", "toast");

    const svgIcon = this.getSvgForType(type);
    if (!!svgIcon) {
      const svg = document.createElement("svg");
      svg.innerHTML = svgIcon;
      newToast.appendChild(svg);
    }

    const span = document.createElement("span");
    span.textContent = message;
    newToast.appendChild(span);

    const actionsDiv = document.createElement("div");
    const button = document.createElement("button");
    button.classList.add("btn", "btn-sm", "btn-ghost", "m-2");
    button.setAttribute("data-action", "click->toast#dismiss");
    button.textContent = "✕";
    actionsDiv.appendChild(button);
    newToast.appendChild(actionsDiv);

    toastContainer.prepend(newToast);
    // TODO: prepend on desktop, append on mobile -- lists should be inverted due to placement on top, bottom respectively
  }

  getSvgForType(type: ToastType) {
    switch (type) {
      case "error":
        return `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-error" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>`;
      case "warning":
        return `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-warning" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>`;
      case "info":
        return `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-info" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`;
      case "success":
        return `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 shrink-0 stroke-success" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>`;
      default:
        return "";
    }
  }
}
