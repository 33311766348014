import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  inMemoryPersistence,
  onAuthStateChanged,
  signInWithPhoneNumber,
  signOut,
  RecaptchaVerifier,
  type Unsubscribe,
  type User,
} from "firebase/auth";
import { sessions } from "../api/all.js";

export type SignInFn = (
  phoneNumber: string,
) => Promise<(confirmationCode: string) => Promise<AppUser>>;
export type SignOutFn = () => Promise<void>;
export type RegisterUserChangeHandler = (
  handler: (u: AppUser | null) => void,
) => Unsubscribe;

export type { User };

// TODO: better home?
export type AppUser = {
  id: string;
  phoneNumber: string;
  name?: string | undefined;
  firebaseUid: string;
};

export type AuthConfig = {
  onRecaptchaSuccess?: () => void;
  signInButtonId: string;
};

// NOTE: Phone number must be in E.164 format: https://www.twilio.com/docs/glossary/what-e164#examples-of-e164-numbers
// export const testPhoneNumber = "+16505554567";
// export const testConfirmationCode = "123456";

export const start = ({ signInButtonId, onRecaptchaSuccess }: AuthConfig) => {
  const app = initializeApp({
    apiKey: import.meta.env.FIREBASE_API_KEY,
    authDomain: import.meta.env.FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.FIREBASE_APP_ID,
    measurementId: import.meta.env.FIREBASE_MEASUREMENT_ID,
  });
  getAnalytics(app);
  const auth = getAuth(app);
  if (import.meta.env.RAILS_ENV !== "production") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  auth.setPersistence(inMemoryPersistence);

  const verifierOptions = {
    size: "invisible",
    badge: "bottomright",
    ...(!!onRecaptchaSuccess ? { callback: onRecaptchaSuccess } : {}),
  };
  const appVerifier = new RecaptchaVerifier(
    auth,
    signInButtonId,
    verifierOptions,
  );
  // @ts-expect-error // TODO: extend global window w d.ts
  window.recaptchaVerifier = appVerifier;

  const railsAppLogin = async (firebaseUser: User) => {
    const token = await firebaseUser.getIdToken();

    const { user: appUser }: { user: AppUser } = await sessions
      .create({
        data: { token },
      })
      .catch((e: unknown) => {
        console.error("failed to create session: ", e);
        throw e; // TODO: actually?? well maybe that makes sense w firebase auth persistence disabled...
      });

    return appUser;
  };

  const signInFn = async (phoneNumber: string) => {
    // NOTE: Phone number must be in E.164 format: https://www.twilio.com/docs/glossary/what-e164#examples-of-e164-numbers
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier,
    ).catch((e: unknown) => {
      console.error("failed to sign in: ", e);
      throw e;
    });

    if (!confirmationResult) throw new Error("sign in w phone number failed");

    const handleConfirmationCode = async (confirmationCode: string) => {
      const result = await confirmationResult
        .confirm(confirmationCode)
        .catch((e: unknown) => {
          console.error("failed to confirm verification code: ", e);
          throw e;
        });
      const firebaseUser = result.user;
      // const operationType = result.operationType;

      const appUser = await railsAppLogin(firebaseUser);

      return appUser;
    };

    return handleConfirmationCode;
  };

  const signOutFn: SignOutFn = async () => {
    const signOutPromise = signOut(auth).catch((e: unknown) => {
      console.error("failed to sign out of firebase auth: ", e);
    });
    const destroySessionPromise = sessions.destroy().catch((e: unknown) => {
      console.error("failed to destroy session: ", e);
    });
    await Promise.all([signOutPromise, destroySessionPromise]);
    location.reload();
  };

  const registerUserChangeHandler: RegisterUserChangeHandler = (handler) =>
    onAuthStateChanged(auth, async (user) => {
      if (!!user) {
        const appUser = await railsAppLogin(user);
        handler(appUser);
        return;
      } else {
        handler(null);
      }
    });

  return {
    signInFn,
    signOutFn,
    registerUserChangeHandler,
  };
};

export default start;
